<template>
  <section class="login">
    <div class="login-container">
      <h1 v-if="isLoginPage" class="login-title">
        Log In
      </h1>
      <h1 v-else class="login-title">
        Sign Up
      </h1>

      <h2 v-if="isLoginPage" class="login-subtitle">
        Don’t have an account?
        <router-link :to="{ name: 'SignUp' }" class="create-button">
          Create an account
        </router-link>
      </h2>
      <h2 v-else class="login-subtitle">
        Already have an account?
        <router-link :to="{ name: 'LogIn' }" class="create-button">
          Log In
        </router-link>
      </h2>

      <div class="divider" />
      <AppInput
        alt
        class="input"
        label="Email address"
        placeholder="Enter your email / name"
        :value.sync="email"
      />
      <AppInput
        alt
        class="input"
        label="Password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :placeholder="isLoginPage ? 'Enter your password' : 'Create a password'"
        :value.sync="pass"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        @pressEnter="emailLogin"
      />

      <AppInput
        v-if="!isLoginPage"
        alt
        class="input"
        label="Confirm password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        placeholder="Confirm password"
        :value.sync="confirmPass"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        @pressEnter="userSignup"
      />
      <div class="forgot-password">
        <div>
          <span v-show="error" class="error-message">{{ error }}</span>
        </div>
        <router-link
          v-if="isLoginPage"
          text
          :to="{ name: 'ForgotPassword' }"
          color="success"
          class="link"
        >
          Forgot password?
        </router-link>
      </div>

      <div v-if="isLoginPage">
        <AppButton
          height="44"
          class="login-button"
          color="var(--button-primary-color)"
          dark
          :loading="loading.btn"
          @click="emailLogin"
        >
          Login
        </AppButton>
      </div>
      <div v-else>
        <AppButton
          height="44"
          color="var(--button-primary-color)"
          class="login-button"
          dark
          :loading="loading.btn"
          @click="userSignup"
        >
          Create account
        </AppButton>
      </div>

      <div class="login-line">
        <div class="line" />
        <div class="content">
          <div v-if="isLoginPage">
            Or login with
          </div>
          <div v-else>
            Or Sign Up with
          </div>
        </div>
        <div class="line" />
      </div>
      <div class="socials">
        <img
          class="image"
          src="/img/google.png"
          alt="google"
          @click="googleLogin"
        >
        <img
          class="image"
          src="/img/facebook.svg"
          alt="facebook"
          @click="facebookLogin"
        >
      </div>
    </div>
  </section>
</template>

<script>
import Fire from '@/plugins/firebase'
export default {
  name: 'Login',
  data() {
    return {
      loading: {
        btn: false
      },
      showPassword: false,
      email: '',
      pass: '',
      confirmPass: '',
      error: null,
      rules: {
        required: (value) => !!value || ''
      }
    }
  },
  computed: {
    isLoginPage() {
      return this.$route.name === 'LogIn'
    }
  },
  methods: {
    async emailLogin() {
      if (!this.isLoginPage) return
      this.loading.btn = true
      if (!this.email || !this.pass) {
        this.error = 'All fields are required'
        this.loading.btn = false
        return
      }
      try {
        await Fire.auth().signInWithEmailAndPassword(this.email, this.pass)
      } catch (error) {
        this.error = error.message
        this.pass = ''
      } finally {
        this.loading.btn = false
      }
    },
    async userSignup() {
      if (this.isLoginPage) return
      this.error = null
      this.loading.btn = true
      if (!this.email || !this.pass || !this.confirmPass) {
        this.error = 'All fields are required'
        this.loading.btn = false
        return
      }
      if (this.pass !== this.confirmPass) {
        this.error = 'Passwords must be equal'
        this.loading.btn = false
        return
      }
      try {
        await Fire.auth().createUserWithEmailAndPassword(this.email, this.pass)
        const currentUser = await Fire.getCurrentUser()
        if (!currentUser.emailVerified) {
          await currentUser.sendEmailVerification()
        }
      } catch (error) {
        this.error = error.message
      } finally {
        this.pass = ''
        this.confirmPass = ''
        this.loading.btn = false
      }
    },
    async facebookLogin() {
      this.loading.btn = true
      this.error = null
      try {
        await Fire.auth().signInWithPopup(new Fire.auth.FacebookAuthProvider())
      } catch (error) {
        this.error = this.getDifferentCredetialErrorMessage(error)
      } finally {
        this.loading.btn = false
      }
    },
    async googleLogin() {
      this.loading.btn = true
      this.error = null
      try {
        await Fire.auth().signInWithPopup(new Fire.auth.GoogleAuthProvider())
      } catch (error) {
        this.error = this.getDifferentCredetialErrorMessage(error)
      } finally {
        this.loading.btn = false
      }
    },
    getDifferentCredetialErrorMessage(error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const registeredProvider =
          error.credential.providerId === 'facebook.com'
            ? 'Google'
            : 'Facebook'
        return `Looks like you registered with ${registeredProvider} please use log in with ${registeredProvider} button to access your account`
      }
      return error.message
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.login {
  padding: 136px 0;

  @include breakpoint-reverse(small) {
    padding: 0;
  }
}

.login-container {
  margin: 0 auto;
  border-radius: var(--border-radius-default);
  padding: 28px 60px;
  max-width: 460px;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 28px;
  }
}

.login-title {
  font-size: var(--font-size-h1);
  text-align: center;
}

.login-subtitle {
  margin-top: 5px;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-base);
  text-align: center;
}

.create-button {
  font-weight: var(--font-weight-bold);
  color: var(--link-color);
  cursor: pointer;
}

.divider {
  margin: 18px 0;
  border: 1px solid var(--primary-border-color);
  opacity: 0.2;
}

.input {
  margin-bottom: 16px;
}

.error-message {
  color: var(--error-color);
}

.forgot-password {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;

  .link {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sm);
    color: var(--link-color);
  }
}

.login-line {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;

  .line {
    margin-top: 9px;
    width: 100%;
    height: 1px;
    max-width: 95px;
    background-color: var(--primary-border-color);
    opacity: 0.2;
  }

  .content {
    width: 100%;
    max-width: 150px;
    font-size: var(--font-size-sm);
    text-align: center;
  }
}

.socials {
  display: flex;
  justify-content: space-between;
  margin: 19px auto 33px;
  max-width: 125px;

  .image {
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}

.login-button {
  display: block;
  margin: 15px auto;
  width: 240px;

  @include breakpoint-reverse(small) {
    width: 100%;
  }
}
</style>
